import getData from "@/__main__/get-data.mjs";
import LolProbuildMatches from "@/game-lol/models/lol-probuild-matches.mjs";
import LoLPros from "@/game-lol/models/lol-probuild-pros.mjs";
import LolProbuildTeams from "@/game-lol/models/lol-probuild-teams.mjs";
import * as API from "@/game-lol/utils/api.mjs";
import { getDefaultedFiltersForProBuilds } from "@/game-lol/utils/util.mjs";

const PER_PAGE = 15;

function fetchData(params, searchParams, state) {
  const [tab] = params;
  const filters = getDefaultedFiltersForProBuilds(searchParams);
  const currPage = state?.page || 1;

  const promises = [];

  promises.push(
    getData(
      API.getProbuildTeams(),
      LolProbuildTeams,
      ["lol", "proBuildTeams"],
      {
        shouldFetchIfPathExists: false,
      },
    ),
  );

  switch (tab) {
    case "history":
    case undefined:
    default:
      if (!filters.team) delete filters.team;
      if (filters.team) filters.teamIds = [filters.team];

      promises.push(
        getData(
          API.getProMatchHistory({
            lane: filters.lane || filters.role,
            teamIds: filters.teamIds || [],
            first: PER_PAGE * currPage,
          }),
          LolProbuildMatches,
          ["lol", "proBuildMatchlist"],
        ),
      );

      break;
    case "live":
      promises.push(
        getData(API.getProbuildPlayers(), LoLPros, ["lol", "proBuildPros"]),
      );
      break;
  }

  return Promise.all(promises);
}

export default fetchData;
